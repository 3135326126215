import React from "react";
import { graphql } from "gatsby";

// Layout Imports
import Default from '../../layouts/Default/Default';
import SEO from '../../layouts/SEO/SEO';

// Component Imports
import Introduction from '../../components/shared/Introduction/Introduction';
import PageSelector from '../../components/shared/PageSelector/PageSelector';
import Circle from '../../components/shared/Circle/Circle';
import TagList from '../../components/shared/TagList/TagList';

// Asset Imports
import Me from '../../assets/images/content/me.svg';

const TagsPage = ({
  data: {allMdx: {group}},
  ...props
}) => {
  return (
    <Default
      location={props.location}
      title='All Tags'
      heading='All Tags'
    >
      <SEO
        title='All Tags'
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <Introduction
        heading='All Tags'
        description='Viewing all tags applied to blog posts'
        imageSource={Me}
      />
      
      <PageSelector
        id='mobile-nav-selector'
        triggerText='All Tags'
      />

      <div className='tag-listing'>
        <div className='tag-listing__container container'>
          <h2 className='tag-listing__heading'>
            Total {group.length} tags
          </h2>

          {group && group.length && (
            <TagList
              tags={group}
              count
              variant='large'
            />
          )}
          
        </div>

      {/* Desktop Circles */}
      <Circle
          color='#7E8588'
          height={575}
          width={575}
          top={-230}
          right={-170}
          opacity={0.1}
          desktop
        />

        <Circle
          color='#7E8588'
          height={535}
          width={535}
          top={-230}
          right={-170}
          opacity={0.1}
          thickness={40}
          desktop
        />

        <Circle
          color='#7E8588'
          height={360}
          width={360}
          top={175}
          right={54}
          opacity={0.05}
          thickness={40}
          desktop
        />

        <Circle
          variant='gradient'
          height={576}
          width={576}
          bottom={-215}
          left={-195}
          desktop
        />

        {/* Mobile Circles */}
        <Circle
          color='#7E8588'
          height={282}
          width={282}
          top={-180}
          right={-140}
          opacity={0.1}
          thickness={15}
          mobile
        />

        <Circle
          color='#7E8588'
          height={252}
          width={252}
          top={-180}
          right={-140}
          opacity={0.1}
          thickness={30}
          mobile
        />

        <Circle
          color='#7E8588'
          height={235}
          width={235}
          top={40}
          right={-130}
          opacity={0.05}
          thickness={20}
          mobile
        />

        <Circle
          variant='gradient'
          height={311}
          width={311}
          bottom={-90}
          left={-177}
          mobile
        />
      </div>
    </Default>
  );
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`